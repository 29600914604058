import React from 'react'
import styled from 'styled-components'
import PawBalance from '../../components/Paw'

import { TYPE } from '../../theme'
import { RowBetween } from '../../components/Row'
import { AutoColumn } from '../../components/Column'
import {ButtonUNIGradient} from '../../components/Button'
import KishuCharacter from '../../assets/images/kishu-character.png'
import Web3Status from '../../components/Web3Status'

import { useActiveWeb3React } from '../../hooks'
import { CardSection, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'

const PageWrapper = styled(AutoColumn)`
  max-width: 680px;
  width: 100%;
`

const VoteCard = styled(DataCard)`
  background: radial-gradient(76.02% 75.41% at 1.84% 0%, #0cccfc 0%, #000000 100%);
  overflow: hidden;
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

export const PawBody = styled.div`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 30px;
  margin-bottom: 1rem;
`

export default function Paw() {
  const { account } = useActiveWeb3React()

  return (
    <>
      <PageWrapper>

        <AutoColumn gap="lg" justify="center">
          <AutoColumn gap="lg" style={{ width: '100%' }}>

            {!account ? (
              <AutoColumn>
                <TitleRow style={{ marginBottom: '1rem' }} padding={'0'}>
                  <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}>
                    🐾 Your Paw Print <ButtonUNIGradient>BETA</ButtonUNIGradient>
                  </TYPE.mediumHeader>
                </TitleRow>

                <PawBody>
                  <CardSection gap='sm'>
                    <AutoColumn gap='md' justify='center'>
                      <img src={KishuCharacter} width={'128px'} alt="KishuCharacter" />
                    </AutoColumn>
                  </CardSection>

                  <CardSection gap='sm'>
                    <AutoColumn gap='md' justify='center'>
                      <TYPE.black fontWeight={600} textAlign="center">
                        Connect to a wallet to view your Paw Print
                      </TYPE.black>
                    </AutoColumn>
                  </CardSection>

                  <CardSection gap='sm'>
                    <AutoColumn gap='md' justify='center'>
                      <Web3Status />
                    </AutoColumn>
                  </CardSection>

                </PawBody>
              </AutoColumn>
            ) : (
              <AutoColumn>
                <VoteCard>
                  <CardBGImage />
                  <CardNoise />
                  <CardSection>
                    <AutoColumn gap="md">
                      <RowBetween>
                        <TYPE.white fontWeight={600}>Disclaimer</TYPE.white>
                      </RowBetween>
                      <RowBetween>
                        <TYPE.white fontSize={14}>
                          {`These calculations represent an estimated APY from Kishu’s instant usage rewards.
                            Projected values are based on past 7 days average volume.
                            The APY does not take into account price fluctuations, change in daily trading volume
                            and is an estimate for illustrative purposes!`}
                        </TYPE.white>
                      </RowBetween>
                    </AutoColumn>
                  </CardSection>
                  <CardBGImage />
                  <CardNoise />
                </VoteCard>

                <TitleRow style={{ marginTop: '1rem', marginBottom: '1rem' }} padding={'0'}>
                  <TYPE.mediumHeader style={{ marginTop: '0.5rem' }}>
                    🐾 Your Paw Print <ButtonUNIGradient>BETA</ButtonUNIGradient>
                  </TYPE.mediumHeader>
                </TitleRow>

                <PawBalance></PawBalance>
              </AutoColumn>
            )}
          </AutoColumn>
        </AutoColumn>
      </PageWrapper>
    </>
  )
}
