import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tokenLogo from '../../assets/images/token-logo.png'
import { UNI } from '../../constants'
// import { useTotalSupply } from '../../data/TotalSupply'
import { useActiveWeb3React } from '../../hooks'
import { useTokenBalance } from '../../state/wallet/hooks'
import { TYPE, UniTokenAnimated } from '../../theme'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { AutoColumn } from '../Column'
import { RowBetween } from '../Row'
import { Break, CardSection } from '../earn/styled'
import QuestionHelper from '../QuestionHelper'

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
`

const FullColumn = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px
  text-align: center;
  @media (min-width: 600px) {
  .FullColumn { grid-template-columns: 1fr); }
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 1fr;
  `};
`

const SingleColumn = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px
  text-align: center;
`

const HalfColumn = styled.div`
  display: inline-grid;
  padding: 4px 20px;
  align-items: center;
`

export const Divider = styled.div`
  border-top: 2px solid #bbb;
  border-radius: 5px;
`

export const PawBody = styled.div`
  position: relative;
  width: 100%;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 30px;
  margin-bottom: 1rem;
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

const PawInputPanel = styled.input`
  color: ${({ theme }) => theme.text3};
  align-items: center;
  height: 2rem;
  border-radius: 36px;
  text-align: center;
  font-size: 1rem;
  margin-right: 8px;
  width: auto;
  min-width: 3.5rem;
  border: 1px solid ${({ theme }) => theme.bg4};
  outline: none;
  :hover {
    border: 1px solid ${({ theme }) => theme.primary1};
    cursor: pointer;
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primary1};
  }
`

function numberWithCommas(x:number, decimals=0) {
  return x.toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// function formatBigNumber(x:number, decimals=1) {
//   if (x > 1e15) {
//     return (x / 1e15).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' Quadrillions'
//   }
//   if (x > 1e12) {
//     return (x / 1e12).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' Trillions'
//   }
//   if (x > 1e9) {
//     return (x / 1e9).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' Billions'
//   }
//   return (x / 1e6).toFixed(decimals).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' Millions'
// }

export default function PawBalance() {
  // window.ethereum.on('accountsChanged', function (accounts) {
  //   window.location.reload(false);
  // })

  const { account, chainId } = useActiveWeb3React()
  const kishu = chainId ? UNI[chainId] : undefined

  let kishuBalance = useTokenBalance(account ?? undefined, kishu) ?? 0
  // const totalSupply = useTotalSupply(kishu)
  kishuBalance = parseFloat(kishuBalance?.toFixed(0, { groupSeparator: '' })) as number
  let percentSupplyOwned = kishuBalance / 1e8
  let kishuPrice = parseFloat(useUSDCPrice(kishu)?.toFixed(4, { groupSeparator: '' })?? '0') ?? 0

  let kishuBalanceUsdt = kishuPrice * kishuBalance

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoaded2, setIsLoaded2] = useState(false);
  const [apiData, setApiData] = useState({dailyTransfers: 0, dailyVolume: 0, holders: 0, kishuPriceEth: 0, kishuPriceUsdt: 0,
  monthlyTransfers: 0, monthlyVolume: 0, totalTransfers: 0, totalVolume: 0, weeklyTransfers: 0, weeklyVolume: 0,
  totalSupply: 0, circulatingSupply: 0, burnSupply: 0});
  const [etherscanData, setEtherscanData] = useState({balance: 0, totalIn: 0, totalOut: 0});
  let api_url = 'https://kishu-api.herokuapp.com/stats'
  // let kishu_starting_block = 12260512
  let kishu_address = '0xa2b4c0af19cc16a6cfacce81f192b024d625817d'
  let etherscan_api_key = '7YSVNR9VZNSWK5FWBX7Y82SW5YK163VSZS'

  type customList = {
    value: number;
    to: string;
  }

  function calculateTransactions(transactionList:customList[], account:string) {
    let result = {
      balance: 0,
      totalIn: 0,
      totalOut: 0
    }
    console.log(transactionList)
    transactionList.forEach((transaction) => {
      // value has decimals so gotta divide by 1e9
      let val = transaction.value / 1e9
      if (transaction.to===account.toLowerCase()) {
        result.balance += val
        result.totalIn += val
      } else {
        result.balance -= val
        result.totalOut += val
      }
    })
    return result
  }

  let etherscan_query = 'https://api.etherscan.io/api?module=account&action=tokentx&contractaddress=' + kishu_address +
                          '&address=' + account + '&page=1&offset=10000&sort=asc&apikey=' + etherscan_api_key

  useEffect(() => {
    fetch(api_url)
      .then(res => res.json())
      .then(
        (result) => {
          setApiData(result);
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setError(error);
          setApiData(error);
          setIsLoaded(true);
        }
      )
  }, [])

  useEffect(() => {
    fetch(etherscan_query)
      .then(res => res.json())
      .then(
        (result) => {
          let transactionList:customList[] = result.result
          let sumResult = calculateTransactions(transactionList, account ?? '')
          setEtherscanData(sumResult);
          setIsLoaded2(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setEtherscanData(error);
          setIsLoaded2(true);
        }
      )
  }, [])

  const [predictionDays, setPredictionDays] = useState(365)
  const [predictionVolume, setPredictionVolume] = useState(1)
  function updatePredictionDays(event: React.ChangeEvent<any>) {
    setPredictionDays(event.target.value);
  }
  function updatePredictionVolume(event: React.ChangeEvent<any>) {
    setPredictionVolume(event.target.value);
  }
  let predictionGainsPercent: number = (Math.pow((1 + (apiData.weeklyVolume * predictionVolume / 7 * 0.02 / 1e17)), predictionDays) - 1) * 100
  let predictionGains: number = kishuBalance * (predictionGainsPercent / 100)
  let predictionGainsPrice: number = predictionGains * kishuPrice

  if (error) {
      return <div>Error</div>;
  } else if (!isLoaded || !isLoaded2) {
    return (
      <ContentWrapper>
        <Break />
        <CardSection gap='sm'>
          <AutoColumn gap='md' justify='center'>
            <UniTokenAnimated width='96px' src={tokenLogo} />{' '}
              <TYPE.black fontSize={36} fontWeight={600} color='black'>
                Loading...
              </TYPE.black>
          </AutoColumn>
        </CardSection>
      </ContentWrapper>
    )
  } else {
    console.log(etherscanData)
    let reflect = (apiData.weeklyVolume / 7) * 0.02
    let reflectPercent = reflect / 1e17
    let reflectDaily = reflect * percentSupplyOwned
    // let reflectDailyUsd = reflectDaily * kishuPrice / 1e9
    let reflectWeekly = reflectDaily * 7
    let reflectWeeklyUsd = reflectWeekly * kishuPrice / 1e9
    let reflectMonthly = reflectDaily * 31
    let reflectMonthlyUsd = reflectMonthly * kishuPrice / 1e9
    let apy = ((Math.pow((1 + reflectPercent), 365) - 1) * 100).toFixed(2)
    let totalReflectGains = Math.max((kishuBalance * 1e9) - etherscanData.balance, 0)
    let totalReflectGainsUsd = totalReflectGains * kishuPrice / 1e9
    let totalInPlusReflect = etherscanData.totalIn + totalReflectGains
    let totalInPriceUsd = totalInPlusReflect * kishuPrice / 1e9
    return (
      <ContentWrapper>
      <PawBody>
        <TYPE.body>
          <Break />
          <CardSection gap='sm'>
            <AutoColumn gap='md' justify='center'>
              <UniTokenAnimated width='96px' src={tokenLogo} />{' '}
            </AutoColumn>
            <AutoColumn gap='md' justify='center'>
              Your $KISHU Balance
                <TYPE.green fontSize={28} fontWeight={600} color='#60b99d'>
                  {numberWithCommas(kishuBalance * 1e9)}
                </TYPE.green>
            </AutoColumn>
            <AutoColumn gap='md' justify='center'>
              Your $KISHU USD Value
                <TYPE.green fontSize={28} fontWeight={600} color='#60b99d'>
                  ${numberWithCommas(kishuBalanceUsdt)}
                </TYPE.green>
            </AutoColumn>
          </CardSection>
          <CardSection gap='sm'>
            <Divider/>
            <AutoColumn gap='md' justify='center' style={{ marginTop: '1rem' }}>
              $KISHU Token Stats
            </AutoColumn>
            <FullColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Price
                </TYPE.main>
                <TYPE.black fontSize={28}>
                  ${(kishuPrice / 1e9).toFixed(11) ?? '-'}
                </TYPE.black>
              </HalfColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Diluted Market Cap
                </TYPE.main>
                <TYPE.black fontSize={28}>
                  ${numberWithCommas(kishuPrice * 1e8) ?? '-'}
                </TYPE.black>
              </HalfColumn>
            </FullColumn>

            <FullColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  24-hour Volume
                </TYPE.main>
                <TYPE.black fontSize={24}>
                  ${numberWithCommas(apiData.dailyVolume * kishuPrice / 1e9)}
                </TYPE.black>
              </HalfColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Monthly Volume
                </TYPE.main>
                <TYPE.black fontSize={24}>
                  ${numberWithCommas(apiData.monthlyVolume * kishuPrice / 1e9)}
                </TYPE.black>
              </HalfColumn>
            </FullColumn>

            <FullColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  % Supply Burnt 🔥
                </TYPE.main>
                <TYPE.black fontSize={28}>
                  {((apiData.burnSupply * 100 / 1e9) / 1e17).toFixed(3) ?? '-'}%
                </TYPE.black>
              </HalfColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Burn USD Value 🔥
                </TYPE.main>
                <TYPE.black fontSize={28}>
                  ${numberWithCommas((apiData.burnSupply / 1e9) * (kishuPrice / 1e9)) ?? '-'}
                </TYPE.black>
              </HalfColumn>
            </FullColumn>
          </CardSection>
        </TYPE.body>
      </PawBody>

      <TitleRow style={{ marginBottom: '1rem' }} padding={'0'}>
        <TYPE.mediumHeader>
          💸 Decentralization and Usage Rewards
        </TYPE.mediumHeader>
      </TitleRow>

      <PawBody>
        <TYPE.body>
          <Break />
          <CardSection gap='sm'>
            <AutoColumn gap='md'>
              <TYPE.main fontSize={13}>
                $KISHU token features a 2% reward whenever users actively transact. That means that the more active is
                the Community, including you, the more rewards in $KISHU you'll have! Here you can see a breakdown of your
                rewards so far, and a projection of future ones for illustrative purposes.
              </TYPE.main>
            </AutoColumn>
            <Break />

            <FullColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Total $KISHU Earned
                </TYPE.main>
                <TYPE.black fontSize={24}>
                  {numberWithCommas(totalReflectGains)}
                </TYPE.black>
              </HalfColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Total USD Value Earned
                </TYPE.main>
                <TYPE.black fontSize={24}>
                  ${numberWithCommas(totalReflectGainsUsd)}
                </TYPE.black>
              </HalfColumn>
            </FullColumn>

            <FullColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Projected Weekly $KISHU Rewards
                </TYPE.main>
                <TYPE.black fontSize={24}>
                  {numberWithCommas(reflectWeekly)}
                </TYPE.black>
              </HalfColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Projected Weekly USD Rewards
                </TYPE.main>
                <TYPE.black fontSize={24}>
                  ${numberWithCommas(reflectWeeklyUsd)}
                </TYPE.black>
              </HalfColumn>
            </FullColumn>

            <FullColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Projected Monthly $KISHU Rewards
                </TYPE.main>
                <TYPE.black fontSize={24}>
                  {numberWithCommas(reflectMonthly)}
                </TYPE.black>
              </HalfColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Projected Monthly USD Rewards
                </TYPE.main>
                <TYPE.black fontSize={24}>
                  ${numberWithCommas(reflectMonthlyUsd)}
                </TYPE.black>
              </HalfColumn>
            </FullColumn>

            <SingleColumn>
              <TYPE.main fontSize={15}>
                APY (Annual Percentage Yield)
                <QuestionHelper text='APY formula: (1 + {hypothetical % daily rewards}) ^ {number of days, 365} - 1' />
              </TYPE.main>
              <TYPE.black fontSize={24}>
                {apy}%
              </TYPE.black>
            </SingleColumn>
          </CardSection>
        </TYPE.body>
      </PawBody>

      <TitleRow style={{ marginBottom: '1rem' }} padding={'0'}>
        <TYPE.mediumHeader>
          🔮 Prediction Maker
        </TYPE.mediumHeader>
      </TitleRow>

      <PawBody>
        <TYPE.body>
          <Break />
          <CardSection gap='sm'>
            <AutoColumn gap='md'>
              <TYPE.main fontSize={13}>
                Curious about the future? Use the Prediction Maker to enter different hypothetical scenarios for your future $KISHU rewards!
              </TYPE.main>
            </AutoColumn>
            <Break />

            <FullColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Days into the future
                </TYPE.main>
                <PawInputPanel type='number' value={predictionDays} onChange={updatePredictionDays} min='2' max='365' />
              </HalfColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  $KISHU Volume Multiplier
                  <QuestionHelper text='What would happen if the trading volume increased? Multiplies the past 7 days average
                    volume, e.g. 1 means current volume, 2 means two times the current volume, 0.5 means half the current volume.' />
                </TYPE.main>
                <PawInputPanel type='number' value={predictionVolume} onChange={updatePredictionVolume} min='0.1' max='10' />
              </HalfColumn>
            </FullColumn>

            <Break />
            <FullColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Estimated $KISHU Gained
                </TYPE.main>
                <TYPE.black fontSize={24}>
                  {numberWithCommas(predictionGains * 1e9)}
                </TYPE.black>
              </HalfColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Estimated USD Value
                </TYPE.main>
                <TYPE.black fontSize={24}>
                  ${numberWithCommas(predictionGainsPrice)}
                </TYPE.black>
              </HalfColumn>
            </FullColumn>
          </CardSection>
        </TYPE.body>
      </PawBody>


      <TitleRow style={{ marginBottom: '1rem' }} padding={'0'}>
        <TYPE.mediumHeader>
          💰 Wallet Activity
        </TYPE.mediumHeader>
      </TitleRow>

      <PawBody>
        <TYPE.body>
          <Break />
          <CardSection gap='sm'>

            <FullColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Total $KISHU In
                </TYPE.main>
                <TYPE.black fontSize={24}>
                  {numberWithCommas(etherscanData.totalIn)}
                </TYPE.black>
              </HalfColumn>
              <HalfColumn>
                <TYPE.main fontSize={15}>
                  Total $KISHU Out
                </TYPE.main>
                <TYPE.black fontSize={24}>
                  {numberWithCommas(etherscanData.totalOut)}
                </TYPE.black>
              </HalfColumn>
            </FullColumn>

            <SingleColumn>
              <TYPE.main fontSize={15}>
                $KISHU Balance Without Rewards
              </TYPE.main>
              <TYPE.black fontSize={24}>
                {numberWithCommas(etherscanData.balance)}
              </TYPE.black>
            </SingleColumn>

            <SingleColumn>
              <TYPE.main fontSize={15}>
                If you never sold any of your $KISHU, now they would be worth
              </TYPE.main>
              <TYPE.black fontSize={24}>
                ${numberWithCommas(totalInPriceUsd)}
              </TYPE.black>
            </SingleColumn>
          </CardSection>
        </TYPE.body>
      </PawBody>
    </ContentWrapper>
    )
  }
}
