import React from 'react'
import styled from 'styled-components'
import { TYPE, ExternalLink } from 'theme'

import { RowBetween } from '../../components/Row'
import { AutoColumn } from '../../components/Column'
import { CardSection } from '../../components/earn/styled'
import AppBody from '../AppBody'

const PageWrapper = styled(AutoColumn)`
  max-width: 480px;
  width: 100%;
`

const TitleRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    flex-direction: column-reverse;
  `};
`

export default function Guardarian() {
  const style = {
   height: '350px',
   width: '100%',
   borderWidth: '0px',
   margin: '2px',
  }

  const url = "https://guardarian.com/calculator/v1?partner_api_token=23214-cb02-dqwe2-9520-38ec22c927s09&default_fiat_amount=300&default_fiat_currency=EUR&theme=kishu&default_crypto_currency=kishu&background=white"
  return (
    <PageWrapper>
      <AutoColumn gap="lg" justify="center">
        <TitleRow style={{ marginBottom: '1rem' }} padding={'0'}>
          <TYPE.largeHeader>
            Get $KISHU via Guardarian.com
          </TYPE.largeHeader>
        </TitleRow>

        <AppBody>
          <CardSection gap='sm'>
            <TYPE.main fontSize={15}>
              Get your Kishu through our partner Guardarian by means of payment systems such as SEPA, VISA,
              Mastercard, Union Pay, and others!
            </TYPE.main>
          </CardSection>

          <CardSection gap='sm'>
            <iframe style={style} src={url} title="Guardarian"></iframe>
          </CardSection>


          <CardSection gap='sm'>
            <TYPE.main fontSize={15}>
              For more information, please check the <ExternalLink href={'https://www.youtube.com/watch?v=HHS1at1TQd4'}>
                how-to video
              </ExternalLink> or the <ExternalLink href={'https://guardarian.com/buy-kishu'}>
                official Guardarian page
              </ExternalLink>.
            </TYPE.main>
          </CardSection>
        </AppBody>
      </AutoColumn>
    </PageWrapper>
  )
}
